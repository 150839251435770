<template>
  <list-crud
    :loading="loading"
    :per-page.sync="perPage"
    :per-page-options="perPageOptions"
    :search-query.sync="searchQuery"
    :items="items"
    :table-columns="tableColumns"
    :sort-by.sync="sortBy"
    :is-sort-dir-desc.sync="isSortDirDesc"
    :current-page.sync="currentPage"
    :from="from"
    :to="to"
    :total-items="totalItems"
    :hide-create-btn="!$store.getters['workingMode/checkPermission']('manage_domains')"
    @click-btn-create="$router.push({ name: 'domains.create' })"
  >
    <template #cell(name)="data">
      <b-link :to="{ name: 'domains.show', params: { id: data.item.id } }">
        {{ data.item.name }}
      </b-link>
    </template>
    <template #cell(status)="data">
      {{ statuses[data.item.status] }}
    </template>

    <!-- Column: Actions -->
    <template #cell(actions)="data">
      <b-dropdown
        variant="link"
        no-caret
        :right="$store.state.appConfig.isRTL"
      >
        <template #button-content>
          <feather-icon
            icon="MoreVerticalIcon"
            size="16"
            class="align-middle text-body"
          />
        </template>

        <b-dropdown-item :to="{ name: 'domains.show', params: { id: data.item.id } }">
          <feather-icon icon="FileTextIcon" />
          <span class="align-middle ml-50">Посмотреть</span>
        </b-dropdown-item>

        <b-dropdown-item
          v-if="$store.getters['workingMode/checkPermission']('manage_domains')"
          @click="deleteItem(data.item)"
        >
          <feather-icon icon="TrashIcon" />
          <span class="align-middle ml-50">Удалить</span>
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </list-crud>
</template>

<script>
import {
  getCurrentInstance, onMounted,
} from 'vue'
import get from 'lodash/get'
import {
  BCard, BCardBody, BRow, BCol, BLink, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import useCrudList from '@/composables/useCrudList'
import { getDomains, deleteDomain } from '@/services/main-api/domains'
import ListCrud from '@/components/crud/ListCrud.vue'

export default {
  name: 'ListView',
  components: {
    ListCrud,
    BCard,
    BCardBody,
    BRow,
    BCol,
    vSelect,
    BLink,
    BDropdown,
    BDropdownItem,
  },
  setup(props) {
    const instance = getCurrentInstance().proxy
    const statuses = {
      pending_verification: 'Ожидание верификации',
      verified: 'Верифицирован',
      failed_verification: 'Ошибка верификации',
    }

    const getItems = async () => {
      let search = null
      if (instance.searchQuery) {
        search = `${instance.searchQuery}`
      }

      try {
        const res = await getDomains({
          companyID: instance.$store.getters['workingMode/selected_company_id'],
          perPage: instance.perPage,
          sortedBy: instance.isSortDirDesc ? 'desc' : 'asc',
          orderBy: instance.sortBy,
          page: instance.currentPage,
          search,
        })

        instance.items = res.data.data
        instance.currentPage = res.data.meta.current_page
        instance.from = res.data.meta.from
        instance.to = res.data.meta.to
        instance.totalItems = res.data.meta.total
      } catch (e) {
        instance.items = []
      }
    }

    const {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,
    } = useCrudList({
      sortBy: 'id',
      isSortDirDesc: true,
      tableColumns: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'name', label: 'Домен', sortable: true },
        { key: 'status', label: 'Статус', sortable: true },
        { key: 'actions', label: 'Действия' },
      ],
      getItems,
      getMoreData: async () => {
      },
      deleteEntity: item => deleteDomain(item.id),
      getQuestionTextBeforeDeletion: item => `Домен ${item.name} будет удален`,
      getTextAfterDeletion: () => 'Домен был удален.',
    })

    onMounted(() => {
      if (!instance.$store.getters['workingMode/mode']) {
        instance.$router.push({ name: 'dashboard' })
      }
    })

    return {
      loading,

      perPageOptions,
      perPage,
      from,
      to,
      currentPage,
      totalItems,

      sortBy,
      searchQuery,
      isSortDirDesc,
      tableColumns,

      items,
      deleteItem,

      get,
      statuses,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
